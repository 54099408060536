var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 mt-3 mt-xl-5"},[_c('Title',{staticClass:"d-none d-xl-block"},[_vm._v(" "+_vm._s(_vm.$t('groups.setting.basic_info.title'))+" ")]),_c('div',{staticClass:"border-xl px-xl-2 py-xl-5"},[_c('div',{staticClass:"w-xl-50"},[(_vm.roleAdmin)?_c('div',{staticClass:"action-area d-flex justify-content-end justify-content-xl-start px-3 mb-6"},[_c('router-link',{attrs:{"to":_vm.goToEditPage()}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_edit'))+" ")])])],1):_vm._e(),_c('div',{staticClass:"content d-flex justify-content-between"},[_c('div',{staticClass:"flex-1 ps-3 pe-1"},[_c('div',{staticClass:"d-flex align-items-center pb-xl-3"},[_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.full-size-avatar",modifiers:{"full-size-avatar":true}}],staticClass:"rounded-pill h--12 w--12 img-cover flex-fixed cursor-pointer d-none d-xl-inline",attrs:{"src":_vm.groupDetail.icon_image_small_path
                  ? _vm.groupDetail.icon_image_small_path
                  : _vm.noGroupAvatar}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-15 fs-xl-19 fwb text-line-clamp-1 px-xl-2",attrs:{"title":_vm.groupDetail.name}},[_vm._v(" "+_vm._s(_vm.groupDetail.name)+" ")])]),_c('div',{staticClass:"d-flex align-items-center py-xl-2"},[_c('div',{staticClass:"fs-12 fs-xl-15 my-1 w-xl--35"},[_vm._v(" "+_vm._s(_vm.$t('groups.setting.basic_info.modified_date'))+" ")]),_c('div',[_c('span',{staticClass:"d-xl-none mx-1"},[_vm._v(":")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.groupDetail.created_at))+" ")])]),_c('div',{staticClass:"group-tag-name fs-12 fs-xl-15 my-1"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-xl--35"},[_vm._v(" "+_vm._s(_vm.$t('groups.setting.basic_info.group_tags'))+" ")]),_c('span',{staticClass:"d-xl-none mx-1"},[_vm._v(":")]),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.groupDetail.group_tags),function(tag,index){return _c('div',{key:index},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                      customClass: _vm.handleLongText(tag.name).isLong
                        ? 'd-block'
                        : 'd-none'
                    }),expression:"{\n                      customClass: handleLongText(tag.name).isLong\n                        ? 'd-block'\n                        : 'd-none'\n                    }",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",attrs:{"title":tag.name}},[_vm._v(" "+_vm._s(_vm.handleLongText(tag.name).text)+" ")])])}),0)])]),_c('div',{staticClass:"group-storage pt-5"},[_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.$t('groups.setting.basic_info.group_storage'))+" "),_c('div',{staticClass:"d-flex flex-column flex-1"},[_c('div',{staticClass:"ps-3"},[_c('span',{staticClass:"pe-1"},[_vm._v(" "+_vm._s(_vm.handleGroupStorge(_vm.groupStorage))+" ")])]),_c('b-progress',{staticClass:"text-danger",attrs:{"value":_vm.groupStorage.storage_used,"max":_vm.groupStorage.storage_capacity,"variant":_vm.groupStorage.percent > 95
                      ? 'danger-tint'
                      : _vm.groupStorage.percent > 80
                      ? 'warning'
                      : 'primary'}}),(_vm.roleAdmin)?_c('div',{staticClass:"text-primary pt-1 hover-o-75"},[_c('span',{staticClass:"cursor-pointer py-1 pe-1",on:{"click":_vm.confirmCleanBin}},[_vm._v(" "+_vm._s(_vm.$t('groups.setting.basic_info.move_all_to_bin'))+" ")])]):_vm._e()],1)])]),_c('div',{staticClass:"notice my-5"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('groups.setting.basic_info.notice'))+" ")]),_c('div',{staticClass:"d-block text-pre text-line-clamp-1",domProps:{"innerHTML":_vm._s(_vm.groupDetail.notice)}})])]),_c('div',{staticClass:"right-side flex-fixed pe-3 ps-1 py-5 d-xl-none"},[_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.full-size-avatar",modifiers:{"full-size-avatar":true}}],staticClass:"rounded-pill h--17 w--17 img-cover flex-fixed cursor-pointer",attrs:{"src":_vm.groupDetail.icon_image_small_path
                ? _vm.groupDetail.icon_image_small_path
                : _vm.noGroupAvatar}})])])])]),_c('ModalConfirm',{attrs:{"confirmMess":_vm.$t('common.message.clean_bin')},on:{"yes":_vm.cleanBin}}),_c('ModalError',{attrs:{"errorMess":_vm.modalMess}}),_c('ModalSuccess',{attrs:{"successMess":_vm.modalMess}}),_c('b-modal',{attrs:{"id":"full-size-avatar","dialog-class":"modal-fullscreen","body-class":"flex-center pb-14","body-bg-variant":"dark-tint","header-bg-variant":"dark-tint","header-close-variant":"light","hide-footer":""}},[_c('div',{staticClass:"h-100 w-100 position-absolute z-9",on:{"click":function($event){return _vm.$bvModal.hide('full-size-avatar')}}}),_c('img',{staticClass:"z-10",attrs:{"src":_vm.groupDetail.icon_image_path
          ? _vm.groupDetail.icon_image_path
          : _vm.noGroupAvatar}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }