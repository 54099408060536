







































































































































































import { Component, Vue } from 'vue-property-decorator'
import { GroupDetail, GroupStorage } from '@/models'
import GroupService from '@/services/GroupService'
import PreviewText from '@/helpers/PreviewText'

/**
 * f2-101
 * グループ設定 基本情報画面
 */
@Component
export default class GroupInfoPage extends Vue {
  private groupDetail: GroupDetail = new GroupDetail()
  private groupStorage: GroupStorage = new GroupStorage()
  private modalMess: string = ''
  private roleAdmin: boolean = this.$store.state.userInfo.user.group_role
    .role_admin
  private noGroupAvatar =
    window.location.origin + require('@/assets/images/group/avatar_default.png')

  created() {
    this.getGroupInformation()
  }

  getGroupInformation() {
    GroupService.getGroupById(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.groupDetail = res.data
          this.groupStorage = new GroupStorage(res.data)
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
  }

  goToEditPage() {
    return ({
      name: 'group-setting-information-edit',
      params: { updateGroupInfo: 'true' }
    })
  }

  confirmCleanBin() {
    this.$bvModal.show('modal-confirm')
  }

  cleanBin() {
    this.$blockui.show()
    GroupService.cleanBin(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.getGroupInformation()
          this.modalMess = this.$t('common.message.cleaned') as string
          this.$bvModal.show('modal-success')
        }
      })
      .catch(() => {
        this.modalMess = this.$t('common.message.clean_fail') as string
        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * format date
   */
  formatDate(date: string) {
    const yyyymmdd = this.$t('common.date_format.yyyy_mm_dd') as string
    return this.moment(date).format(yyyymmdd)
  }

  /**
   * Handle long text
   */
  handleLongText(text: string) {
    return PreviewText.covertToPreviewText(text, 40)
  }

  /**
   * Handle group storage description
   */
  handleGroupStorge(groupStorage: GroupStorage) {
    return (
      [
        Math.floor(groupStorage.storage_used * 100) / 100 + groupStorage.type,
        groupStorage.storage_capacity + groupStorage.type
      ].join('/') + ` (${Math.floor(groupStorage.percent)}%)`
    )
  }
}
